import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getCategory } from '../../selectors/categories-selectors';
import { connect } from '../runtime-context';
import Link from '../link/internal-link';
import {
  getIsCategoryLabelLayoutText,
  getIsCategoryLabelUppercase,
  shouldUseMobileSettings,
} from '../../../post-list-widget/selectors/category-label-selectors';
import styles from './post-list-item-category-label.scss';

const PostListItemCategoryLabel = ({
  mainCategory,
  isCategoryLabelLayoutText,
  isCategoryLabelUpperCase,
  postListLayout,
  useMobileSettings,
}) => {
  const categoryLabelLayoutClass = classNames([
    styles.link,
    useMobileSettings && styles.mobile,
    isCategoryLabelLayoutText ? styles.categoryLabelText : styles.categoryLabelButton,
  ]);
  const containerClass = classNames([styles.container, styles[postListLayout]]);
  const categoryLink = `/categories/${mainCategory.slug}`;
  const label = isCategoryLabelUpperCase ? mainCategory.menuLabel.toUpperCase() : mainCategory.menuLabel;

  return (
    <div className={containerClass}>
      <Link to={categoryLink} className={categoryLabelLayoutClass} addHoverClasses={false}>
        {label}
      </Link>
    </div>
  );
};

PostListItemCategoryLabel.propTypes = {
  post: PropTypes.object.isRequired,
  mainCategory: PropTypes.object.isRequired,
  isCategoryLabelLayoutText: PropTypes.bool.isRequired,
  isCategoryLabelUpperCase: PropTypes.bool.isRequired,
  postListLayout: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { post }) => ({
  mainCategory: getCategory(state, post.categoryIds[0]),
  isCategoryLabelLayoutText: getIsCategoryLabelLayoutText(state),
  isCategoryLabelUpperCase: getIsCategoryLabelUppercase(state),
  useMobileSettings: shouldUseMobileSettings(state),
});

export default connect(mapStateToProps)(PostListItemCategoryLabel);
